import React from 'react'
import { useLangContext } from '../context/lang.context'
import Headline from './Headline'
import ContainerLarge2 from './ContainerLarge2'
import Section from './Section'

const ContainersLarge = ({ data, query, minimal }) => {
  const { lang } = useLangContext()
  const { title, desc } = data
  const containers = query.containers.edges
  return (
    <>
      <Section top bottomXS>
        <Headline h={2} title={title[lang]} desc={desc[lang]} large gradient />
      </Section>
      <Section bottom mobile bottomXS={minimal}>
        {containers.map((container, id) => (

          <ContainerLarge2
            minimal={minimal}
            key={id}
            container={container}
            last={id === containers.length - 1}
            h={3}
          />
        ))}
      </Section>
    </>
  )
}

export default ContainersLarge
